import React from "react";
import { useAuthContext } from "../contexts/authContext";
import PageLayout from "../components/PageLayout";
import PageBoundary from "../components/PageBoundary";
import PageContent from "../components/PageContent";
import LoadingStatus from "../components/LoadingStatus";

interface resource {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

const Home: React.FC = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  // TODO: Replace with actual SEO data
  const tempSEOData = {
    title: "BAM Applied Insights",
    description: "Balyasny's AI-Enabled Research Platform for Core Investors",
  };

  return (
    <PageLayout seo={tempSEOData} showNav={false} showFooterDocs={false}>
      <PageContent className="flex flex-col">
        <PageBoundary className="flex flex-col flex-1">
          <LoadingStatus loadingType="page_loading" />
        </PageBoundary>
      </PageContent>
    </PageLayout>
  );
};

export default Home;
